
import { defineComponent, ref, onMounted } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useRouter } from "vue-router";
import roc_list, {
  business_natures,
  group_types,
  industry_types_pc,
  business_types,
} from "@/core/data/genericData";
import axios from "axios";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { mask } from "vue-the-mask";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";
import { ElTable } from "element-plus";
import { ActionsFi } from "@/store/enums/StoreFiEnums";
import { boolean } from "yup/lib/locale";
import moment from "moment";

export default defineComponent({
  name: "add-client-modal",
  directives: { mask },
  components: {},

  setup() {
    const store = useStore();
    // const nob_data = business_natures;
    // const group_type_data = group_types;
    // const business_types_data = business_types;
    // const roc_list_data = roc_list;
    const group_type_data = ref([]);
    const nob_data = ref([]);
    const business_types_data = ref([]);
    const roc_list_data = ref([]);

    const industry_types_data = industry_types_pc;
    const formRef = ref<null | HTMLFormElement>(null);
    const addCompanyModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const router = useRouter();

    const multipleTableRef = ref<InstanceType<typeof ElTable>>();
    const multipleSelection: any = ref([]);
    const productList = ref<SalesOffer[]>([]);
    const supplier_data = ref<Array<Supplier>>([]);
    const consignee_data = ref<Array<Supplier>>([]);

    const type_data = ref([]);
    const incoterms_data = ref([]);
    const expeceted_delivery_data = ref([]);
    const tableData = ref<Array<SalesOffer>>([]);
    const dialogSelectProductTableVisible = ref(false);
    const uom_data = ref<Array<UOM>>([]);
    const addPlanningIndentRef = ref<null | HTMLElement>(null);
    const customer_branch_data = ref<Array<any>>([]);
    const sales_order_data = ref<Array<any>>([]);
    const po_data = ref([]);
    const is_consignee_data = ref([
      { id: 1, name: "Yes" },
      { id: 2, name: "No" },
    ]);

    interface Supplier {
      company_id: number;
      company_name: string;
      company_name_search: string;
      company_name_alternative: string;
      company_nob_id: number;
      company_nob_name: string;
      company_group_type_id: number;
      company_group_type_name: number;
      company_industry_type_id: number;
      company_industry_type_name: string;
      company_business_type_id: number;
      company_business_type_name: string;
      company_roc_id: number;
      company_roc_name: string;
      company_doi: string;
      company_cin_llpin: string;
      company_registration_number: string;
      company_pan: string;
      company_website: string;
      company_verified_yes_no: number;
      company_trade_type_id: number;
      active: number;
    }

    interface UOM {
      uom_id: number;
      uom_display_name: string;
    }
    getUOM();

    interface SalesOffer {

      sales_order_details_id: string;
      sales_order_id: string;
      sales_order_no: string;
      client_po_no: string;
      consignee_company_name: string;
      product_name: string;
      order_qty: string;
      uom_name: string;
    }

    onMounted(async () => {
      setCurrentPageBreadcrumbs("Create Dispatch Order", []);
    });

    getAddData();
    // getCompanyList();
    getIncoterms();
    getDeliveryTerms();
    getPurchaseRequitionType();

    const formData = ref({
      do_date: new Date(),
      supplier_select: "",
      customer_branch_select:"",
      so_select:[],
      is_moving_goods_select: true,
    });


    const resetFormData = () => {
      formData.value = {
        do_date: new Date(),
        supplier_select: "",
        customer_branch_select:"",
        so_select:[],
        is_moving_goods_select: true,
      }
      tableData.value = [];

    }


    const myData = ref<Array<SalesOffer>>([]);
    const rules = ref({
      do_date: [
        {
          required: true,
          message: "Customer is required",
          trigger: "change",
        },
      ],
      supplier_select: [
        {
          required: true,
          message: "Customer is required",
          trigger: "change",
        },
      ],
      customer_branch_select: [
        {
          required: true,
          message: "Customer branch is required",
          trigger: "change",
        },
      ],
      so_select: [
        {
          required: true,
          message: "SO no is required",
          trigger: "change",
        },
      ],
    });

    const setCompanyData = async (data) => {
      var user = JSON.parse(JwtService.getToken());
      debugger;
      let product_list = multipleSelection.value.map((item) => {
        return item.sa_id;
      });
      if (product_list.length <= 0) {
        console.log("validation failed");
        Swal.fire({
          text: "Please Select At Least One PO.",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        loading.value = false;
        return;
      }

      const request_body = {
        moving_goods_yn : data.is_moving_goods_select ? 1 : 0,
        do_date : moment(data.do_date).format("YYYY-MM-DD"),
        user_id : user.user_id,
        sa_ids : product_list

      }
      console.log('final data', request_body);
      try {
        await store
          .dispatch(ActionsFi.CUST_CREATE_SALES_DISPATCH_ORDER, request_body)
          .then(({ data, is_error, status }) => {
            loading.value = false;
            console.log(data);
            if(is_error == false){
            setTimeout(() => {
              loading.value = false;

              Swal.fire({
                html: ""+

                      data?.do_no+

                      "<br>Sales Dispatch Order created successfully!",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                router.push({ name: "apps-sales-dispatch-order-listing" });
              });
            }, 2000);
            }else{
              loading.value = false;
            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
            }
          })
          .catch(({ response }) => {
            loading.value = false;
            console.log(response);
            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
          });
      } catch (e) {
        loading.value = false;
        console.log(e);
      }
    };


    const submit = async () => {
      loading.value = true;

      if (!formRef.value) {
        loading.value = false;
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          await setCompanyData(formData.value);
        } else {
          loading.value = false;
          return false;
        }
      });
    };

    function addProduct() {
      // tableData.value.push({
      //   Product: "test",
      //   Quantity: "3",
      //   Rate: "4",
      //   Batch_no: "3",
      //   UOM: "kg",
      // });
      // myData.value.push({
      //   prno: "PR-1",
      //   product: "prod1",
      //   quantity: "39",
      //   uom: "kg",
      // });
      //   console.log("table data.." + tableData.value);
    }
    function deleteRow(row) {
      myData.value.splice(row, 1);
    }
    async function getPurchaseRequitionType() {
      await store
        .dispatch(ActionsFi.CUST_GET_REQUISITION_TYPES)
        .then(({ data }) => {
          debugger;
          type_data.value = data;
          // console.log(JSON.stringify(data.data.result_list));
          // console.log(JSON.stringify(product_data.value));
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getUOM() {
      await store
        .dispatch(ActionsFi.CUST_GET_UOM)
        .then(({ data }) => {
          uom_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getCompanyList(query: string) {
      debugger;
      if (query.length < 3) {
        return;
      }

      // let values = { trade_type: 1 };
      let values = { search_term: query };
      await store
        .dispatch(Actions.CUST_GET_SUPPLIER_DROPDOWN_LIST, values)
        .then(({ data }) => {
          supplier_data.value = data;
          consignee_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getCustomerBranchList() {
      let values = {
        company_id: formData.value["supplier_select"],
        company_branch_id: 0,
        page: 1,
        records_per_page: 1000,
      };
      await store
        .dispatch(Actions.CUST_GET_BRANCH_LIST, values)
        .then(({ data }) => {
          debugger;
          customer_branch_data.value = data?.result_list;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getSalesOrderList() {
      let values = {
        customer_company_id: formData.value["supplier_select"],
        customer_company_branch_id: formData.value["customer_branch_select"],
        page: 1,
        records_per_page: 1000,
      };
      await store
        .dispatch(ActionsFi.CUST_GET_PENDING_SO_BY_CUSTOMER_LIST, values)
        .then(({ data }) => {
          debugger;
          sales_order_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    


    async function getIncoterms() {
      await store
        .dispatch(ActionsFi.CUST_GET_INCOTERMS_DATA)
        .then(({ data }) => {
          incoterms_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getDeliveryTerms() {
      await store
        .dispatch(ActionsFi.CUST_GET_INCOTERMS_DATA)
        .then(({ data }) => {
          expeceted_delivery_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getPOData() {
      let values = {
        company_id: formData.value["supplier_select"],
      };
      await store
        .dispatch(ActionsFi.CUST_GET_PENDING_PURCHASE_ORDERS_LIST, values)
        .then(({ data }) => {
          debugger;
          po_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getAddData() {
      await store
        .dispatch(Actions.CUST_GET_COMPANY_PAGE_DATA)
        .then(({ body }) => {
          nob_data.value = body.business_natures;
          group_type_data.value = body.group_types;
          group_type_data.value.splice(0, 1);
          business_types_data.value = body.business_types;
          roc_list_data.value = body.roc_list;
          roc_list_data.value.splice(0, 1);
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    const handleSelectionChange = (val: SalesOffer[]) => {
      multipleSelection.value = val;
    };

    const onCancle = () => {
      multipleTableRef.value!.clearSelection();
      multipleSelection.value = [];
      dialogSelectProductTableVisible.value = false;
    };

    async function getTableData() {
      debugger;
      let params = {
        so_ids: formData.value["so_select"],
      };
      await store
        .dispatch(ActionsFi.CUST_GET_PENDING_SO, params)
        .then(({ data }) => {
          debugger;
          data.map((so) => {
            so.order_qty = so.order_qty + "" + so.uom_name;
          });
          tableData.value = data;
          // console.log(JSON.stringify(data.data.result_list));
          // console.log(JSON.stringify(product_data.value));
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    const openDilogue = () => {
      if (formData.value["type_select"] === "") {
        Swal.fire({
          text: "Please select purchase requisition type first!",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        return false;
      } else {
        dialogSelectProductTableVisible.value = true;
      }
    };

    const onContinue = () => {
      debugger;
      if (multipleSelection.value.length === 0) {
        Swal.fire({
          text: "Please select atleast one user",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        return false;
      } else {
        productList.value = multipleSelection.value;
        myData.value = multipleSelection.value;
        dialogSelectProductTableVisible.value = false;
      }
    };
    return {
      group_type_data,
      industry_types_data,
      business_types_data,
      addPlanningIndentRef,
      roc_list_data,
      formData,
      nob_data,
      type_data,
      rules,
      submit,
      formRef,
      loading,
      addCompanyModalRef,
      addProduct,
      myData,
      deleteRow,
      getPurchaseRequitionType,
      dialogSelectProductTableVisible,
      tableData,
      multipleTableRef,
      multipleSelection,
      handleSelectionChange,
      onContinue,
      onCancle,
      getTableData,
      openDilogue,
      supplier_data,
      consignee_data,
      incoterms_data,
      is_consignee_data,
      expeceted_delivery_data,
      getIncoterms,
      po_data,
      getDeliveryTerms,
      getPOData,
      getCompanyList,
      getCustomerBranchList,
      customer_branch_data,
      getSalesOrderList,
      sales_order_data,
      resetFormData
      
    };
  },
});
